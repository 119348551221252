import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {}
  interface ThemeOptions {}
}

export const formWidth = 400;
export const boxShadow = "0px 3px 20px #00000008";
export const PrimaryButtonColor = "#94CACD";

export default createTheme({
  typography: {
    fontFamily: "'Cerebri Sans Pro'",
  },
  palette: {
    primary: {
      main: "#000",
      light: "#0000000F",
    },
    secondary: {
      main: "#94CACD40",
    },
    background: {
      default: "#f5f6f7",
      paper: "#fff",
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "@media(min-width: 0px) and (max-width: 600px)": {
            maxWidth: "100%!important",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 9999,
          borderWidth: 2,
          paddingRight: 28,
          paddingLeft: 28,
          paddingTop: 6,
          paddingBottom: 6,
          "&:hover": {
            borderWidth: 2,
          },
          "&:focus": {
            borderWidth: 2,
          },
        },
        outlinedSecondary: {
          color: "#fff",
          boxShadow: "0px 0px 15px #0000001A",
          backgroundColor: PrimaryButtonColor,
          borderColor: PrimaryButtonColor,
          "&:hover": {
            color: "#000",
            borderColor: "#000",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: "300",
          marginBottom: 4,
          color: "#000",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "100%!important",
        },
        inputRoot: {
          padding: "4px 14px",
        },
        input: {
          padding: "6px 0!important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: "0 14px!important",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: 15,
          color: "#0000000F",
          "&.Mui-completed": {
            color: "#94c4cd",
          },
          "&.Mui-active": {
            color: "#94CACD",
          },
        },
        text: {
          display: "none",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTopWidth: "2px",
          borderColor: "#000",
        },
        vertical: {
          marginLeft: "7px",
          marginTop: "-11px",
          marginBottom: "-11px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#94c4cd",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
          boxShadow,
        },
        input: {
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 14,
          background: "#fff",
          lineHeight: 1.3,
          borderRadius: 4,
        },
        notchedOutline: {
          borderRadius: 4,
          borderColor: "#0000000F",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontWeight: "bold",
        },
        h4: {
          fontSize: "28px",
          fontWeight: "600",
          letterSpacing: "-0.42px",
        },
      },
    },
  },
});
