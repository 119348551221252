import React from "react";
import ReactDOM from "react-dom";
import { merge } from "lodash";
import "./i18n";

const environment = process.env.NODE_ENV || "development";
const root = document.getElementById("root");

if (root !== null) {
  fetch("/config.json")
    .then((res) => res.json())
    .then((data) => {
      if (data) {
        global.appConfig = merge(data, { environment });
        /* eslint-disable global-require */
        const App = require("./App").default;
        ReactDOM.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>,
          root
        );
        return;
      }

      throw new Error("App configuration not found");
    })
    .catch((err) => {
      throw new Error(err);
    });
}
