import { FC } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type CookieLinkProps = {};

const CookieLink: FC<CookieLinkProps> = () => {
  const onButtonClick = () => {
    //@ts-ignore
    if (typeof cc !== "undefined" && cc.showSettings) {
      //@ts-ignore
      cc.showSettings();
    }
  };

  return (
    <Button
      onClick={onButtonClick}
      sx={{ textDecoration: "underline", opacity: 0.25 }}
    >
      <Typography variant="caption">Update cookie settings</Typography>
    </Button>
  );
};

export default CookieLink;
