import React from 'react';
import { Quill } from 'react-quill';
import { useTranslation } from 'react-i18next';
import SelectListToolbar from './SelectListToolbar';
import Icon from '../Icon';
import { useTestId } from '../testId';

Quill.register('modules/custom', true);

const EmailTemplateToolbar = ({ listItems }) => {
  const { t } = useTranslation();
  const testId = useTestId('emailTemplateToolbar', [listItems]);

  return (
    <SelectListToolbar
      listItems={listItems}
      data-testid={testId}
      className="template"
      tooltipText={"Load template"}
      label={<Icon name="file-alt" id="before-select" />}
    />
  );
};

export default EmailTemplateToolbar;
