/* eslint-disable */
import React from 'react';
import BaseToolbar from './toolbar/BaseToolbar';
import BaseTextToolbar from './toolbar/BaseTextToolbar';
import TextAligmentToolbar from './toolbar/TextAligmentToolbar';
import PositionToolbar from './toolbar/PositionToolbar';
import Toolbar from './toolbar/Toolbar';
import CareerTextToolbar from './toolbar/CareerTextToolbar';
import PipelineToolbar from './toolbar/PipelineToolbar';
import CareerTextImageToolbar from './toolbar/CareerTextImageToolbar';
import EmailVariablesToolbar from './toolbar/EmailVariablesToolbar';
import EmailVariablesTemplatesToolbar from './toolbar/EmailVariablesTemplatesToolbar';
import ProductAddToolbar from './toolbar/ProductAddToolbar';
import PitchToolbar from './toolbar/PitchToolbar';
import PositionTemplateToolbar from './toolbar/PositionTemplateToolbar';
import ScheduleInterviewToolbar from './toolbar/ScheduleInterviewToolbar';
import CandidateEmailToolbar from './toolbar/CandidateEmailToolbar';

const ToolbarFactory = ({ toolbar, toolbarId, options }) => {
  let foundToolbar;
  switch (toolbar) {
    case 'base':
      foundToolbar = <BaseToolbar />;
      break;
    case 'base-text':
      foundToolbar = <BaseTextToolbar />;
      break;
    case 'text-align':
      foundToolbar = <TextAligmentToolbar />;
      break;
    case 'position':
      foundToolbar = <PositionToolbar {...options} />;
      break;
    case 'email-var-templates':
      foundToolbar = <EmailVariablesTemplatesToolbar {...options} />;
      break;
    case 'career-text':
      foundToolbar = <CareerTextToolbar />;
      break;
    case 'career-text-image':
      foundToolbar = <CareerTextImageToolbar />;
      break;
    case 'pipeline':
      foundToolbar = <PipelineToolbar />;
      break;
    case 'product-add':
      foundToolbar = <ProductAddToolbar />;
      break;
    case 'email-variables':
      foundToolbar = <EmailVariablesToolbar />;
      break;
    case 'position-template-variables':
      foundToolbar = <PositionTemplateToolbar {...options} />;
      break;
    case 'pitch':
      foundToolbar = <PitchToolbar {...options} />;
      break;
    case 'schedule-interview':
      foundToolbar = <ScheduleInterviewToolbar {...options} />;
      break;
    case 'candidate-email-toolbar':
      foundToolbar = <CandidateEmailToolbar {...options} />;
      break;
    default:
      foundToolbar = <BaseToolbar />;
      break;
  }
  return <Toolbar toolbarId={toolbarId}>{foundToolbar}</Toolbar>;
};

export default ToolbarFactory;
