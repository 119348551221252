import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import sv from "./locales/sv/translation.json";

export const checkLanguage = () => {
  return navigator.language.substring(0, 2);
};

export const isEn = () => {
  return checkLanguage() === "en";
};

export const isSv = () => {
  return checkLanguage() === "sv";
};

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: checkLanguage(),
  // @ts-ignore
  debug: process.env === "development",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
