import { FC, useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Page from "../../components/Page/Page";
import CookieLink from "../../components/CookieLink/CookieLink";
import theme, { formWidth } from "../../theme";
import Stepper, { steps } from "../../components/Stepper/Stepper";
import CenteredCell from "../../components/CenteredPageCell/CenteredPageCell";
import {
  AppCtx,
  billingValidationSchema,
  BillingState,
} from "../../store/store";
import config from "../../config";
import api from "../../lib/api";
import { useTranslation } from "react-i18next";

type BillingProps = {};
const Billing: FC<BillingProps> = () => {
  const { t } = useTranslation();
  const {
    state: { data },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik<BillingState>({
    initialValues: data.billing,
    validationSchema: billingValidationSchema,
    onSubmit: async (values) => {
      dispatch({ type: "UPDATE_BILLING", payload: values });
      try {
        setLoading(true);
        await api.post("/order", {
          packageName:
            data.packages !== null
              ? appConfig.packages[data.packages].name
              : "",
          ...data.contactDetails,
          ...data.company,
          ...data.position,
          jobCategoryName: appConfig.jobFunction[data.position.jobCategoryName],
          description: data.position.description?.replace(
            /<\/?[^>]+(>|$)/g,
            ""
          ),
          ...values,
        });
        navigate("/thank-you");
      } catch (error) {
        // TODO - add error handling
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    if (!data.position.applyFormLink) {
      navigate("/position");
    }
  }, [data?.position.applyFormLink]);

  const onNext = () => {
    formik.handleSubmit();
  };
  const onPrev = () => {
    navigate("/position");
  };

  return (
    <Page
      FooterContent={() => (
        <Stepper
          onNext={onNext}
          onPrev={onPrev}
          activeStep={steps.findIndex((step) => step === "Billing")}
          pending={loading}
          // Note - not a pretty solution, but BE requires consentTermsAndConditions to be a string
          disabled={formik.values.consentTermsAndConditions === "false"}
        />
      )}
    >
      <CenteredCell>
        <Box maxWidth={formWidth}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign={"center"}
          >
            {t("pages.billing.page-title")}
          </Typography>
          <Box mt={4} />
          <form onSubmit={formik.handleSubmit}>
            <InputLabel>{t("general.billing-address")}*</InputLabel>
            <TextField
              fullWidth
              id="billingAddress"
              name="billingAddress"
              placeholder="Dunder Mifflin, Stockholm"
              value={formik.values.billingAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.billingAddress &&
                Boolean(formik.errors.billingAddress)
              }
            />
            <Box mt={2} />
            <InputLabel>{t("general.billing-reference")}*</InputLabel>
            <TextField
              fullWidth
              required
              placeholder="Finance"
              id="billingReference"
              name="billingReference"
              value={formik.values.billingReference}
              onChange={formik.handleChange}
              error={
                formik.touched.billingReference &&
                Boolean(formik.errors.billingReference)
              }
            />
            <Box mt={2} />
            <InputLabel>{t("general.other-information")}*</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={9}
              id="informationDetails"
              name="informationDetails"
              value={formik.values.informationDetails}
              onChange={formik.handleChange}
              error={
                formik.touched.informationDetails &&
                Boolean(formik.errors.informationDetails)
              }
            />
            <Box mt={2} />
            <FormControlLabel
              control={
                <Checkbox
                  name="previewAdsBeforeGoingLive"
                  defaultChecked
                  onChange={({ target }) => {
                    formik.setFieldValue(
                      "previewAdsBeforeGoingLive",
                      String(target.checked)
                    );
                  }}
                />
              }
              label={t("pages.billing.preview-ads-before-live")}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="consentTermsAndConditions"
                  defaultChecked
                  onChange={({ target }) => {
                    formik.setFieldValue(
                      "consentTermsAndConditions",
                      String(target.checked)
                    );
                  }}
                />
              }
              label={
                <a
                  href={"https://weselect.com/legal/privacy-policy"}
                  target={"_blank"}
                >
                  <Typography fontSize={14}>
                    {t("pages.billing.terms")}
                  </Typography>
                </a>
              }
            />
          </form>
          <Box
            mt={2}
            mb={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
          >
            <CookieLink />
          </Box>
        </Box>
      </CenteredCell>
      <CenteredCell backgroundColor={theme.palette.secondary.main}>
        <Box sx={{ width: "100%", maxWidth: 500 }}>
          <Typography
            variant="h5"
            component="p"
            gutterBottom
            textAlign={"center"}
            mb={6}
          >
            {t("general.review-your-order")}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.email")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.contactDetails?.email || "-"}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.name")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.contactDetails?.firstName || "-"}{" "}
              {data.contactDetails?.lastName}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.company")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.company?.companyName || "-"}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.product")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.packages !== null
                ? appConfig.packages[data.packages].name.replaceAll("</br>", "")
                : "-"}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.job-title")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.position?.jobTitle || "-"}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", maxWidth: 500 }}
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.location")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {data.position?.locationTitle || "-"}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.delivery-time")}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.business-days")}
            </Typography>
          </Box>
          <Box
            mt={3}
            mb={2}
            sx={{
              height: "1px",
              width: "100%",
              background: "#9aa7a8",
            }}
          ></Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" component="p" gutterBottom>
              {t("general.total")}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="subtitle1" component="p">
                {config.packages[data.packages || 0].price} SEK
              </Typography>
              <Typography variant="caption" component="p">
                {t("general.vat")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CenteredCell>
    </Page>
  );
};

export default Billing;
