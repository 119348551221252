import React from 'react';
import AligmentToolbar from './AligmentToolbar';
import BaseTextToolbar from './BaseTextToolbar';
import TextSizeToolbar from './TextSizeToolbar';
import { useTestId } from '../testId';

const CareerTextImageToolbar = () => {
  useTestId('careerTextImageToolbar');

  return (
    <>
      <AligmentToolbar />
      <BaseTextToolbar withVideo />
      <TextSizeToolbar />
    </>
  );
};

export default React.memo(CareerTextImageToolbar);
