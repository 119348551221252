import React, { FC } from "react";
import Box from "@mui/material/Box";
import theme, { boxShadow } from "../../theme";

type FooterProps = {};

const Footer: FC<FooterProps> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      height={30}
      padding={4}
      boxShadow={boxShadow}
      borderTop={`2px solid ${theme.palette.primary.light}`}
    >
      {children}
    </Box>
  );
};

export default Footer;
