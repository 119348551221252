import { v4 as uuidv4 } from 'uuid';

/**
 * Event emitter
 * @exports event-emitter
 */
const events = {
  events: {},
  /**
   * Calling of function that's registered
   * @param {String} event
   * @param {Object} data
   */
  $emit(event, data) {
    if (!this.events[event]) return;
    const callbacks = Object.values(this.events[event]);
    callbacks.forEach((callback) => callback(data));
  },
  /**
   * Registration of events to be called
   * @param {String} event
   * @param {Function} callback
   */
  $on(event, callback, identifier) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event] = {
      ...this.events[event],
      ...{
        [identifier || uuidv4()]: callback,
      },
    };
  },
  /**
   * Removing of saved function
   * @param {String} event
   */
  $off(event, identifier) {
    if (identifier && this.events[event] && this.events[event][identifier]) {
      Reflect.deleteProperty(this.events[event], identifier);
    } else if (this.events[event]) {
      Reflect.deleteProperty(this.events, event);
    }
  },
};

export default events;
