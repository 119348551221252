import React, { useEffect, useMemo } from 'react';
import { Quill } from 'react-quill';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import BaseToolbar from './BaseToolbar';
import SelectListToolbar from './SelectListToolbar';
import Icon from '../Icon';
import { useTestId } from '../testId';
import placeHolderConstants from '../PlaceHolderWysiwyg';

Quill.register('modules/custom', true);

const id = uuidv4();

const ScheduleInterviewToolbar = ({ listItems }) => {
  const { t } = useTranslation();
  const testId = useTestId('scheduleInterviewToolbar', [listItems]);

  const translationForCss = t('html-editor.variables');

  const translationStyleObject = useMemo(
    () => ({
      '--translatedVariablesString': `"${translationForCss}"`,
    }),
    [translationForCss]
  );

  useEffect(() => {
    const elem = document.querySelector('.ql-select-variable .ql-selected');

    if (elem) {
      elem.classList.remove('ql-selected');
    }
  }, []);

  return (
    <>
      <BaseToolbar />
      <span
        className="ql-formats"
        style={translationStyleObject}
        data-testid={testId}
        data-tip={t('html-editor.insert-variables')}
        href="#"
        id={`insert-variables-${id}`}
      >
        <UncontrolledTooltip
          placement="top"
          data-testid={`${testId}--tooltip`}
          target={`insert-variables-${id}`}
        >
          {t('html-editor.insert-variables')}
        </UncontrolledTooltip>
        <select
          className="ql-placeholder ql-select-variable"
          data-testid={`${testId}--selectVariable`}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          defaultValue=""
        >
          {placeHolderConstants.map((item) => (
            <option
              data-testid={`${testId}--${item.id}`}
              key={item.id}
              value={item.id}
            >
              {t(item.translationKey)}
            </option>
          ))}
        </select>
      </span>
      <SelectListToolbar
        listItems={listItems}
        data-testid={testId}
        className="template"
        tooltipText={t('html-editor.load-template')}
        label={<Icon name="file-alt" id="before-select" />}
      />
    </>
  );
};

export default ScheduleInterviewToolbar;
