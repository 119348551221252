import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

const Icon = ({ name, type, size, color, fontSize, ...rest }) => (
  <FontAwesomeIcon
    icon={[type, name]}
    size={size}
    color={color}
    {...rest}
    style={{
      fontSize,
      ...(rest.style || {}),
    }}
  />
);

Icon.defaultProps = {
  type: 'fal',
  size: '1x',
  color: 'inherit',
  fontSize: null,
};

export default Icon;
