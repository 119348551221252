import React from 'react';
import EmailVariablesToolbar from './EmailVariablesToolbar';
import EmailTemplateToolbar from './EmailTemplateToolbar';
import { useTestId } from '../testId';

const EmailVariablesTemplatesToolbar = ({ listItems }) => {
  useTestId('emailVariablesTemplatesToolbar');

  return (
    <>
      <EmailVariablesToolbar />
      <EmailTemplateToolbar listItems={listItems} className="position" />
    </>
  );
};

export default EmailVariablesTemplatesToolbar;
