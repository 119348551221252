import React, { FunctionComponent, useEffect, useContext } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import api from "./lib/api";
import theme from "./theme";
import Routes from "./routes";
import { StateProvider, AppCtx, ICompanyIndustries } from "./store/store";
import "./App.css";

const App: FunctionComponent = () => {
  const { dispatch } = useContext(AppCtx);

  const getConfig = async () => {
    try {
      const {
        data,
      }: {
        data: {
          payload: {
            jobFunctions: string[];
            companyIndustries: ICompanyIndustries[];
          };
        };
      } = await api.get("/config");

      dispatch({ type: "SET_CONFIG", payload: data?.payload });
    } catch (error) {
      // TODO - add error handling
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MuiThemeProvider theme={theme}>
        <StateProvider>
          <Router>
            <Routes />
          </Router>
        </StateProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
