import React, { FC } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

export const steps: string[] = [
  "Package",
  "Contact",
  "Company",
  "Position",
  "Billing",
];

export interface StepperWrapperProps {
  activeStep: number;
  onNext: () => void;
  onPrev: () => void;
  disabled?: boolean;
  pending?: boolean;
}

const StepperWrapper: FC<StepperWrapperProps> = ({
  onNext,
  onPrev,
  activeStep,
  disabled,
  pending,
}) => {
  const { t } = useTranslation();
  const showMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onPrev();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      width={"100%"}
      alignItems={"center"}
    >
      <Button
        variant="outlined"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 6 }}
      >
        {t("general.previous")}
      </Button>
      <Box flex={1} />
      <Box flex={2}>
        {!showMobile && (
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    {index + 1}. {t(`general.${label.toLowerCase()}`)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
      </Box>
      <Box flex={1} />
      <LoadingButton
        color={disabled || pending ? "primary" : "secondary"}
        variant="outlined"
        sx={{ ml: 6 }}
        onClick={handleNext}
        disabled={disabled || pending}
        loading={pending}
      >
        {activeStep === steps.length - 1
          ? t("general.finish")
          : t("general.next")}
      </LoadingButton>
    </Box>
  );
};

export default StepperWrapper;
