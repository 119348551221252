import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Theme, useMediaQuery } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

type PageProps = {
  title?: string;
  titleWidth?: number;
  centered?: boolean;
  enforceDirection?: "row" | "column";
  FooterContent?: FC<any>;
};

const Page: FC<PageProps> = ({
  children,
  title,
  titleWidth,
  centered,
  enforceDirection,
  FooterContent,
}) => {
  const theme = useTheme();
  const showMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <Header />
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{
          backgroundColor: theme.palette?.background.default,
          overflowY: "auto",
        }}
      >
        {title && (
          <Box mt={1} p={4} maxWidth={titleWidth || "100%"}>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              textAlign={"center"}
            >
              {title}
            </Typography>
          </Box>
        )}
        <Box
          display={"flex"}
          flex={1}
          width={"100%"}
          flexDirection={enforceDirection || (showMobile ? "column" : "row")}
          alignItems={"center"}
          justifyContent={centered ? "center" : undefined}
        >
          {children}
        </Box>
      </Box>
      <Footer>{FooterContent ? <FooterContent /> : null}</Footer>
    </Box>
  );
};

export default Page;
