/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTestId } from '../testId';

const id = uuidv4();
const ProductAddToolbar = () => {
  const { t } = useTranslation();
  const testId = useTestId('productAddToolbar');

  return (
    <>
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <button
          type="button"
          className="ql-bold"
          data-testid={`${testId}--bold`}
          id={`bold-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--bold--tooltip`}
            target={`bold-${id}`}
          >
            {t('html-editor.bold')}
          </UncontrolledTooltip>
        </button>
        <button
          type="button"
          data-tip={t('html-editor.italic')}
          className="ql-italic"
          data-testid={`${testId}--italic`}
          id={`italic-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--italic--tooltip`}
            target={`italic-${id}`}
          >
            {t('html-editor.italic')}
          </UncontrolledTooltip>
        </button>
        <button
          type="button"
          data-testid={`${testId}--underline`}
          data-tip={t('html-editor.underline')}
          className="ql-underline"
          id={`underline-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--underline--tooltip`}
            target={`underline-${id}`}
          >
            {t('html-editor.underline')}
          </UncontrolledTooltip>
        </button>
      </div>
      <div className="ql-formats">
        <button
          type="button"
          data-testid={`${testId}--insertImage`}
          data-tip={t('html-editor.insert-image')}
          className="ql-image"
          id={`insert-image-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--insert-image--tooltip`}
            target={`insert-image-${id}`}
          >
            {t('html-editor.insert-image')}
          </UncontrolledTooltip>
        </button>
      </div>
    </>
  );
};

export default React.memo(ProductAddToolbar);
