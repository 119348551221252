import React from 'react';
import BaseTextToolbar from './BaseTextToolbar';
import { useTestId } from '../testId';

const TextAligmentToolbar = ({ withVideo }) => {
  useTestId('textAlignmentToolbar', [2]);
  return (
    <>
      <BaseTextToolbar withVideo={withVideo} />
    </>
  );
};

TextAligmentToolbar.defaultProps = {
  withVideo: false,
};

export default React.memo(TextAligmentToolbar);
