import { FC, useContext, useEffect } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Box, { BoxProps } from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DatePicker from "@mui/lab/DatePicker";
import Select from "@mui/material/Select";
import Page from "../../components/Page/Page";
import Config from "../../config";
import CookieLink from "../../components/CookieLink/CookieLink";
import Stepper, { steps } from "../../components/Stepper/Stepper";
import GoogleMapsSearch from "../../components/GoogleMapsSearch/GoogleMapsSearch";
import WysiwygEditor from "../../components/WysiwygUnsafe/WysiwygEditor";
import { TestIdProvider } from "../../components/WysiwygUnsafe/testId";
import { formWidth } from "../../theme";
import {
  AppCtx,
  PositionState,
  positionValidationSchema,
} from "../../store/store";
import { useTranslation } from "react-i18next";

const JOB_FUNCTIONS_FALLBACK = Object.keys(Config.jobFunction).map((i) => i);

const FormCell: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box flexBasis={"50%"} boxSizing={"border-box"} {...props}>
      {children}
    </Box>
  );
};

export type PositionProps = {};
const Position: FC<PositionProps> = () => {
  const { t } = useTranslation();
  const {
    state: {
      config: { jobFunctions },
      data: { position, company },
    },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const formik = useFormik<PositionState>({
    initialValues: position,
    validationSchema: positionValidationSchema,
    onSubmit: (values) => {
      dispatch({ type: "UPDATE_POSITION", payload: values });
      navigate("/billing");
    },
  });

  useEffect(() => {
    if (!company.companyName) {
      navigate("/company");
    }
  }, [company]);

  const onNext = () => {
    formik.handleSubmit();
  };
  const onPrev = () => {
    navigate("/company");
  };

  return (
    <Page
      title={t("pages.position.page-title")}
      FooterContent={() => (
        <Stepper
          onNext={onNext}
          onPrev={onPrev}
          activeStep={steps.findIndex((step) => step === "Position")}
        />
      )}
      enforceDirection="column"
    >
      <Box maxWidth={formWidth * 1.4}>
        <form onSubmit={formik.handleSubmit}>
          <FormCell>
            <InputLabel>{t("general.job-title")}*</InputLabel>
            <TextField
              fullWidth
              required
              id="jobTitle"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            />
          </FormCell>
          <Box mt={2} />
          <FormCell>
            <InputLabel>{t("general.location")}</InputLabel>
            <GoogleMapsSearch
              onValueChange={(place) => {
                formik.setFieldValue(
                  "locationTitle",
                  place?.structured_formatting.main_text
                );
                formik.setFieldValue("locationPlaceId", place?.place_id);
              }}
            />
          </FormCell>
          <Box mt={2} />
          <FormCell overflow="hidden">
            <InputLabel>{t("general.job-category")}*</InputLabel>
            <FormControl fullWidth>
              <Select
                id="jobCategoryName"
                name="jobCategoryName"
                value={formik.values.jobCategoryName}
                onChange={(event) => {
                  formik.setFieldValue(
                    "jobCategoryName",
                    event.target.value as string
                  );
                }}
                inputProps={{ shrink: "false" }}
                error={
                  formik.touched.jobCategoryName &&
                  Boolean(formik.errors.jobCategoryName)
                }
              >
                <MenuItem value={"0"}>
                  <em>Select</em>
                </MenuItem>
                {(jobFunctions || JOB_FUNCTIONS_FALLBACK).map((job) => {
                  return (
                    <MenuItem value={job} key={job}>
                      {t(`jobFunction.${job}`) || job}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormCell>
          <Box mt={2} />
          <FormCell>
            <InputLabel>{t("general.last-application-date")}*</InputLabel>
            <DatePicker
              disablePast
              value={formik.values.lastApplicationDate}
              views={["year", "month", "day"]}
              onChange={(date) =>
                formik.setFieldValue(
                  "lastApplicationDate",
                  moment(date).format("YYYY-MM-DD")
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="lastApplicationDate"
                  error={
                    formik.touched.lastApplicationDate &&
                    Boolean(formik.errors.lastApplicationDate)
                  }
                />
              )}
            />
          </FormCell>
          <Box mt={2} />
          <InputLabel>{t("general.description")}</InputLabel>
          <Box>
            {/* @ts-ignore */}
            <TestIdProvider>
              {/* @ts-ignore */}
              <WysiwygEditor
                text={formik.values.description}
                toolbarId="position"
                toolbar={"position"}
                onEditorStateChange={(desc: unknown) =>
                  formik.setFieldValue("description", desc)
                }
                options={{ listItems: [] }}
                bounds=".position-form-wysiwyg"
                className="position-form-wysiwyg"
                resource="position"
                editorName="description"
                invalid={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
              />
            </TestIdProvider>
          </Box>
          <Box mt={2} />
          <InputLabel>{t("general.apply-form-link")}*</InputLabel>
          <TextField
            fullWidth
            required
            placeholder="https://"
            id="applyFormLink"
            name="applyFormLink"
            value={formik.values.applyFormLink}
            onChange={formik.handleChange}
            error={
              formik.touched.applyFormLink &&
              Boolean(formik.errors.applyFormLink)
            }
          />
        </form>
        <Box mt={4} />
      </Box>
      <CookieLink />
      <Box mt={2} mb={2} />
    </Page>
  );
};

export default Position;
