import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseToolbar from './BaseToolbar';
import PositionTemplateVariablesToolbar from './PositionTemplateVariablesToolbar';
import Icon from '../Icon';

const PitchToolbar = (test) => {
  const { t } = useTranslation();
  const { listItems } = test;

  return (
    <>
      <BaseToolbar />
      <PositionTemplateVariablesToolbar
        listItems={listItems}
        tooltipText={t('html-editor.load-template')}
        label={<Icon name="file-alt" id="before-select" />}
        className="position"
      />
      {/* <SelectListToolbar
        listItems={listItems}
        data-testid={testId}
        className="position"
        tooltipText={t('html-editor.load-template')}
        label={<Icon name="file-alt" id="before-select" />}
      /> */}
    </>
  );
};

export default React.memo(PitchToolbar);
