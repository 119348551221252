import React, { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Config from "../../config";
import Stepper, { steps } from "../../components/Stepper/Stepper";
import Page from "../../components/Page/Page";
import CenteredCell from "../../components/CenteredPageCell/CenteredPageCell";
import theme, { formWidth } from "../../theme";
import MockImage from "../../assets/images/mock.png";
import {
  AppCtx,
  CompanyState,
  companyValidationSchema,
} from "../../store/store";
import { useTranslation } from "react-i18next";

export type CompanyProps = {};

const INDUSTRIES_FALLBACK = Object.keys(Config.industries).map((i) => ({
  code: i,
  name: Config.industries[i],
}));

const Company: FC<CompanyProps> = () => {
  const { t } = useTranslation();
  const {
    state: {
      config: { companyIndustries },
      data: { company, contactDetails },
    },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const formik = useFormik<CompanyState>({
    initialValues: company,
    validationSchema: companyValidationSchema,
    onSubmit: (values) => {
      dispatch({ type: "UPDATE_COMPANY", payload: values });
      navigate("/position");
    },
  });

  useEffect(() => {
    if (!contactDetails.email) {
      navigate("/contact");
    }
  }, []);

  const onNext = () => {
    formik.handleSubmit();
  };
  const onPrev = () => {
    navigate("/contact");
  };

  return (
    <Page
      FooterContent={() => (
        <Stepper
          onNext={onNext}
          onPrev={onPrev}
          activeStep={steps.findIndex((step) => step === "Company")}
        />
      )}
    >
      <CenteredCell>
        <Box maxWidth={formWidth}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign={"center"}
          >
            {t("pages.company.page-title")}
          </Typography>
          <Box mt={4} />
          <form onSubmit={formik.handleSubmit}>
            <InputLabel>{t("general.company-name")}*</InputLabel>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
            />
            <Box mt={2} />
            <InputLabel>{t("general.company-website")}*</InputLabel>
            <TextField
              fullWidth
              required
              placeholder="https://..."
              id="companyWebsite"
              name="companyWebsite"
              value={formik.values.companyWebsite}
              onChange={formik.handleChange}
              error={
                formik.touched.companyWebsite &&
                Boolean(formik.errors.companyWebsite)
              }
            />
            <Box mt={2} />
            <InputLabel>{t("general.company-industry")}*</InputLabel>
            <FormControl fullWidth>
              <Select
                id="industry"
                name="industry"
                value={formik.values.industry}
                onChange={(event) =>
                  formik.setFieldValue("industry", event.target.value as string)
                }
                inputProps={{ shrink: "false" }}
                error={
                  formik.touched.industry && Boolean(formik.errors.industry)
                }
              >
                <MenuItem value={"0"}>
                  <em>Select</em>
                </MenuItem>
                {(companyIndustries || INDUSTRIES_FALLBACK).map((job) => {
                  return (
                    <MenuItem value={job.code} key={job.code}>
                      {job.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </form>
        </Box>
      </CenteredCell>
      <CenteredCell backgroundColor={theme.palette.secondary.main}>
        <img
          alt={"Example ad"}
          width={"45%"}
          style={{ maxWidth: "270px" }}
          src={MockImage}
        />
        <Typography textAlign={"center"} style={{ opacity: 0.5 }}>
          Example ad
        </Typography>
      </CenteredCell>
    </Page>
  );
};

export default Company;
