import React from 'react';
import BaseToolbar from './BaseToolbar';
import AligmentToolbar from './AligmentToolbar';
import { useTestId } from '../testId';

const PipelineToolbar = () => {
  useTestId('pipelineToolbar');
  return (
    <>
      <BaseToolbar />
      <AligmentToolbar />
    </>
  );
};

export default PipelineToolbar;
