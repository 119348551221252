import React from "react";
import TextAligmentToolbar from "./TextAligmentToolbar";
import TextSizeToolbar from "./TextSizeToolbar";
import EmailTemplateToolbarToolbar from "./EmailTemplateToolbar";
import { useTestId } from "../testId";

const PositionToolbar = ({ listItems }) => {
  useTestId("positionToolbar", [listItems]);
  return (
    <>
      <TextAligmentToolbar withVideo />
      <TextSizeToolbar />
    </>
  );
};

export default React.memo(PositionToolbar);
