import { FC, useContext, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stepper, { steps } from "../../components/Stepper/Stepper";
import Package from "../../components/Package/Package";
import Page from "../../components/Page/Page";
import CookieLink from "../../components/CookieLink/CookieLink";
import theme from "../../theme";
import { AppCtx } from "../../store/store";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../lib/use-query";

type PackagesProps = {};

const Packages: FC<PackagesProps> = () => {
  const {
    state: {
      data: { packages },
    },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    if (query && query.get("package")) {
      onNext(parseInt(query.get("package") as string));
    }
  }, [query]);

  const onNext = (index?: number) => {
    if (typeof index !== "undefined" && index >= 0) {
      dispatch({ type: "UPDATE_PACKAGES", payload: index });
    }

    navigate("contact");
  };

  const onPrev = () => {
    return;
  };

  const { t } = useTranslation();

  return (
    <Page
      title={t("pages.packages.page-title")}
      enforceDirection="column"
      FooterContent={() => (
        <Stepper
          onNext={() => (packages !== null ? onNext() : null)}
          onPrev={onPrev}
          activeStep={steps.findIndex((step) => step === "Package")}
          disabled={packages === null}
        />
      )}
    >
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: theme.breakpoints.values, pb: 4 }}
        data-testid="_packages-container"
      >
        {config.packages.map(
          (
            {
              name,
              price,
              fb,
              linkedin,
              snapchat,
              instagram,
              sales,
              img,
              bestseller,
              "smart-advertising": smartAdvertising,
              "smart-advertising-steps": smartAdvertisingSteps,
              "ad-views": adViews,
              "advertising-days": advertisingDays,
              "job-level": jobLevel,
              "extra-ad": extraAd,
              "extra-ad-1": extraAd1,
              clicks,
            },
            index
          ) => {
            const socials = [];

            if (fb) {
              socials.push("Facebook");
            }

            if (linkedin) {
              socials.push("LinkedIn");
            }

            if (snapchat) {
              socials.push("Snapchat");
            }

            if (instagram) {
              socials.push("Instagram");
            }

            let ads = [];

            if (smartAdvertising) {
              ads.push(
                t("general.advertising", {
                  title: smartAdvertising,
                })
              );
            }

            if (smartAdvertisingSteps) {
              ads.push(
                t("general.advertising-steps", {
                  title: smartAdvertisingSteps,
                })
              );
            }

            if (advertisingDays) {
              ads.push(
                t("general.days-of-advertising", {
                  type: advertisingDays.toString().includes("-")
                    ? t("general.months")
                    : t("general.days"),
                  days: advertisingDays,
                })
              );
            }

            if (adViews) {
              ads.push(
                t("general.ad-views-on-average", {
                  views: adViews,
                })
              );
            }

            if (clicks) {
              ads.push(
                t("general.clicks-on-average", {
                  clicks,
                })
              );
            }

            if (jobLevel) {
              ads.push(
                t("general.suitable-for", {
                  title: t(jobLevel),
                })
              );
            }

            if (extraAd && t(extraAd)) {
              ads.push(t(extraAd));
            }

            if (extraAd1 && t(extraAd1)) {
              ads.push(t(extraAd1));
            }

            return (
              <Grid key={`package-${index}`} item xs={12} sm={12} md={3}>
                <Package
                  title={t(name)}
                  ads={ads}
                  socials={socials.map((item) => item.toLowerCase())}
                  price={t(String(price))}
                  onNext={() => onNext(index)}
                  selected={packages === index}
                  sales={!!sales}
                  img={img}
                  bestseller={!!bestseller}
                />
              </Grid>
            );
          }
        )}
      </Grid>
      <CookieLink />
      <Box mb={4} />
    </Page>
  );
};

export default Packages;
