import React from 'react';
import EmailVariablesToolbar from './EmailVariablesToolbar';
import EmailTemplateToolbar from './EmailTemplateToolbar';
import TextAligmentToolbar from './TextAligmentToolbar';
import TextSizeToolbar from './TextSizeToolbar';

const EmailVariablesTemplatesToolbar = ({ listItems }) => {
  return (
    <>
      <TextAligmentToolbar withVideo />
      <TextSizeToolbar />
      <EmailVariablesToolbar />
      <EmailTemplateToolbar listItems={listItems} className="position" />
    </>
  );
};

export default EmailVariablesTemplatesToolbar;
