import axios from "axios";
import { BASE_API_URL } from "../config";

const instance = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default instance;
