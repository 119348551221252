/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import '../wysiwyg/custom.picker.sass';
import { useTestId } from '../testId';

const id = uuidv4();
const SelectListToolbar = ({ listItems, className, label, tooltipText }) => {
  const { t } = useTranslation();
  const testId = useTestId('selectListToolbar');

  useEffect(() => {
    // workaround for CSS based dropdown
    const pickerClassSelector = document.querySelector(
      `.ql-${className} .ql-picker-label`
    );
    const selectedlassSelector = document.querySelector(
      `.ql-${className} .ql-selected`
    );
    if (pickerClassSelector) {
      pickerClassSelector.innerHTML = `${
        selectedlassSelector ? selectedlassSelector.innerHTML : ''
      }`;
    }
  }, []);

  return (
    <div
      className="ql-formats"
      data-tip={tooltipText}
      data-testid={testId}
      id={`select-list-${id}`}
      href="#"
    >
      <UncontrolledTooltip
        placement="top"
        data-testid={`${testId}--selectList--tooltip`}
        target={`select-list-${id}`}
      >
        {tooltipText}
      </UncontrolledTooltip>
      <div className="ql-custom-dropdown-label">{label}</div>
      <select
        className={classnames(`ql-${className}`, 'ql-custom-dropdown')}
        data-testid={`${testId}--selectList`}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        defaultValue=""
      >
        <option value="" disabled>
          {listItems && listItems.length
            ? t('wysiwyg.template-choose-one')
            : t('general.no-data-found')}
        </option>
        {listItems &&
          listItems.map((listItem, index) => (
            <option
              data-testid={`${testId}--selectList--${index}`}
              key={`${listItem.value}-${index}`}
              value={JSON.stringify(listItem)}
            >
              {listItem.label}
            </option>
          ))}
      </select>
    </div>
  );
};

SelectListToolbar.defaultProps = {
  label: '',
};

export default SelectListToolbar;
