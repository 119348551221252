import { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import Packages from "../pages/Package/Package";
import ContactDetails from "../pages/ContactDetails/ContactDetails";
import Company from "../pages/Company/Company";
import Position from "../pages/Position/Position";
import ThankYou from "../pages/ThankYou/ThankYou";
import Billing from "../pages/Billing/Billing";

const Index: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Packages />} />
      <Route path="/contact" element={<ContactDetails />} />
      <Route path="/company" element={<Company />} />
      <Route path="/position" element={<Position />} />
      <Route path="/billing" element={<Billing />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
};

export default Index;
