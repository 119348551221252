/* eslint-disable */
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "./wysiwyg/quill.ws.sass";
import classnames from "classnames";
import "./wysiwyg/quill.dark.sass";
import "./wysiwyg/quill.fix.sass";
import ToolbarFactory from "./ToolbarFactory";
import placeHolderConstants from "./PlaceHolderWysiwyg";
import { EditorVariables as placeholderPositionTemplateConstants } from "./PositionTemplate";
import "./wysiwyg.sass";
import events from "./events";
import { TEMPLATE_CHANGE_EVENT } from "./constants";

function insertTemplate(value) {
  const template = JSON.parse(value);
  const textLength = this.quill.getLength();
  this.quill.deleteText(0, textLength);
  this.quill.pasteHTML(0, template.value);
  this.quill.setSelection(this.quill.getLength() + 1);
  events.$emit(TEMPLATE_CHANGE_EVENT, template.item);
}

const WysiwygEditor = ({
  text,
  onEditorStateChange,
  onTemplateChange,
  onBlur,
  toolbar,
  placeholder,
  readOnly,
  className,
  toolbarId,
  theme,
  options,
  bounds,
  invalid,
  resource,
  editorName,
  ...rest
}) => {
  let wysiwyg = [];
  const listTypeToolbars = [
    "position",
    "email-var-templates",
    "position-template-variables",
    "pitch",
    "schedule-interview",
    "candidate-email-toolbar",
  ];
  const positionTemplateTypeToolbars = ["position-template-variables", "pitch"];
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        template: insertTemplate,
      },
    },
    placeholder: {
      delimiters: ["{", "}"],
      placeholders: positionTemplateTypeToolbars.includes(toolbar)
        ? placeholderPositionTemplateConstants
        : placeHolderConstants,
    },
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    events.$on(TEMPLATE_CHANGE_EVENT, (item) => {
      onTemplateChange(item);
    });
    return () => {
      events.$off(TEMPLATE_CHANGE_EVENT);
    };
  });

  if (listTypeToolbars.includes(toolbar)) {
    wysiwyg = options.listItems && (
      <>
        <ToolbarFactory
          toolbar={toolbar}
          onTemplateChange={onTemplateChange}
          toolbarId={toolbarId}
          options={options}
        />
        <ReactQuill
          defaultValue={text}
          readOnly={readOnly}
          theme="snow"
          modules={modules}
          placeholder={placeholder}
          formats={[
            "bold",
            "italic",
            "font",
            "underline",
            "list",
            "bullet",
            "link",
            "header",
            "size",
            "format",
            "align",
            "image",
            "video",
            "placeholder",
            "template",
          ]}
          onChange={(content) => {
            onEditorStateChange(content);
          }}
          bounds={bounds}
          {...rest}
        />
      </>
    );
  } else {
    wysiwyg = (
      <>
        <ToolbarFactory toolbar={toolbar} toolbarId={toolbarId} />
        <ReactQuill
          defaultValue={text || ""}
          readOnly={readOnly}
          theme="snow"
          modules={modules}
          placeholder={placeholder}
          formats={[
            "bold",
            "italic",
            "font",
            "underline",
            "list",
            "bullet",
            "link",
            "header",
            "size",
            "format",
            "align",
            "image",
            "video",
            "placeholder",
          ]}
          bounds={bounds}
          onChange={(content) => {
            onEditorStateChange(content);
          }}

          invalid={invalid}
          {...rest}
        />
      </>
    );
  }

  return (
    <div
      className={classnames(`ql-${theme}`, className, {
        "is-invalid": invalid === true,
      })}
      onBlur={onBlur}
      data-testid={`${resource}-${editorName}-wysiwyg`}
    >
      {wysiwyg}
    </div>
  );
};

WysiwygEditor.defaultProps = {
  toolbar: "base",
  onTemplateChange: () => {},
  placeholder: "",
  className: "",
  toolbarId: "toolbar",
  theme: "snow",
  options: {},
  bounds: ".transition-group-container",
  invalid: false,
  onBlur: () => {},
  resource: "general",
};

export default WysiwygEditor;
