import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import BaseToolbar from "./BaseToolbar";
import { useTestId } from "../testId";

const id = uuidv4();
const BaseTextToolbar = ({ withVideo }) => {
  const { t } = useTranslation();
  const testId = useTestId("baseTextToolbar");

  return (
    <>
      <BaseToolbar withVideo={withVideo} />
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        href="#"
        id={`base-text-toolbar-${id}`}
      >
        <select
          data-testid={`${testId}--option-regular-header`}
          className="ql-header"
          defaultValue="4"
        >
          <option data-testid={`${testId}--option-title`} value="1">
            {"Title"}
          </option>
          <option data-testid={`${testId}--option-subtitle`} value="3">
            {"Subtitle"}
          </option>
          <option data-testid={`${testId}--option-regular-text`} value="4">
            {"Regular Text"}
          </option>
        </select>
      </div>
    </>
  );
};

BaseTextToolbar.defaultProps = {
  withVideo: false,
};

export default React.memo(BaseTextToolbar);
