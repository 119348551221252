import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../assets/images/check.svg";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import Snapchat from "../../assets/images/snapchat.svg";

type PackageProps = {
  title: string;
  ads: string[];
  price: string;
  socials: string[];
  selected?: boolean;
  onNext(): void;
  sales: boolean;
  img: string | string[];
  bestseller: boolean;
};

const Package: FC<PackageProps> = ({
  title,
  ads,
  price,
  socials,
  selected,
  onNext,
  sales,
  img,
  bestseller,
}) => {
  const { t } = useTranslation();

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!sales) {
      onNext();
      return;
    }
    window.open("https://www.weselect.com/book-meeting", "_blank");
  };

  return (
    <Card
      sx={{
        height: "100%",
        boxShadow: selected ? "0px 5px 52px #ccc" : "0px 3px 48px #0000000A",
        borderRadius: "10px",
        cursor: "pointer",
        ":hover": {
          boxShadow: "0px 5px 52px #ccc",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          boxSizing: "border-box",
          pl: 4,
          pr: 4,
        }}
      >
        <Box display="flex" justifyContent="center" mb={4} position="relative">
          {bestseller && (
            <Typography
              variant="body1"
              style={{
                position: "absolute",
                right: -65,
                top: 0,
                transform: "rotate(45deg)",
                transformOrigin: "center",
                background: "#94CACD",
                color: "white",
                fontWeight: 500,
                padding: "5px 0",
                width: 150,
                textAlign: "center",
              }}
            >
              Bestseller
            </Typography>
          )}
          {Array.isArray(img) ? (
            <Box position="relative" display="flex" justifyContent="center">
              {img.map((item, index) => {
                let transform = "";

                if (img.length === 3) {
                  if (index === 2) {
                    transform = "translateX(-50%)";
                  } else {
                    transform = "scale(0.9)";
                  }
                }

                return (
                  <img
                    key={item}
                    src={`/images/${item}`}
                    style={{
                      width: "40%",
                      margin: img.length === 3 ? 0 : "0 5px",
                      position: index === 2 ? "absolute" : "relative",
                      left: index === 2 ? `50%` : 0,
                      top: 0,
                      zIndex: index === 2 ? 10 : 5,
                      transform,
                    }}
                    alt=""
                  />
                );
              })}
            </Box>
          ) : (
            <img src={`/images/${img}`} style={{ width: "40%" }} alt="" />
          )}
        </Box>
        <Typography
          variant="h6"
          style={{
            fontWeight: 600,
            lineHeight: "1.75rem",
          }}
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Grid item xs zeroMinWidth mt={2}>
          {ads.map((ad) => (
            <Box
              display="flex"
              alignItems="flex-start"
              key={ad}
              marginBottom={1}
            >
              <img
                src={CheckIcon}
                alt=""
                style={{
                  width: 15,
                  marginRight: 12,
                  marginTop: 2,
                }}
              />
              <Typography variant="caption">{ad}</Typography>
            </Box>
          ))}
        </Grid>
        <Box
          mt={4}
          display="flex"
          justifyContent="flex-end"
          flexDirection="column"
          alignItems="center"
          flex={1}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ gap: "0.5rem" }}
            mb={2}
          >
            {socials.map((social) => {
              let img = Facebook;

              if (social === "instagram") img = Instagram;
              else if (social === "linkedin") img = LinkedIn;
              else if (social === "snapchat") img = Snapchat;

              return (
                <img
                  src={img}
                  alt=""
                  key={social}
                  style={{
                    width: 26,
                  }}
                />
              );
            })}
          </Box>
          <Box mt={2} />
          <Typography variant="body2">{price}</Typography>
          <Box mt={2} />
          <Button variant="outlined" onClick={handleClick}>
            {t(sales ? "general.contact-sales" : "general.choose")}
          </Button>
          <Box mt={1} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Package;
