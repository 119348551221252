import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTestId } from '../testId';

const id = uuidv4();
const TextSizeToolbar = () => {
  const { t } = useTranslation();
  const testId = useTestId('textSizeToolbar');

  return (
    <div
      className="ql-formats"
      href="#"
      data-testid={testId}
      id={`text-size-${id}`}
      onMouseDown={(e) => { e.preventDefault(); }}
    >
      <select className="ql-size" defaultValue="normal">
        <option data-testid={`${testId}--textSize--large`} value="large">{"Large Text"}</option>
        <option data-testid={`${testId}--textSize--normal`} value="normal">{"Normal Text"}</option>
        <option data-testid={`${testId}--textSize--small`} value="small">{"Small Text"}</option>
      </select>
    </div>
  );
};

export default TextSizeToolbar;
