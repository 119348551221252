import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import TextAligmentToolbar from './TextAligmentToolbar';
import TextSizeToolbar from './TextSizeToolbar';
import PositionTemplateVariablesToolbar from './PositionTemplateVariablesToolbar';
import { useTestId } from '../testId';

const PositionTemplateToolbar = ({ listItems }) => {
  const { t } = useTranslation();

  useTestId('positionTemplateToolbar', [listItems]);
  return (
    <>
      <TextAligmentToolbar withVideo />
      <TextSizeToolbar />
      <PositionTemplateVariablesToolbar
        listItems={listItems}
        tooltipText={t('html-editor.load-template')}
        label={<Icon name="file-alt" id="before-select" />}
        className="position"
      />
    </>
  );
};

export default React.memo(PositionTemplateToolbar);
