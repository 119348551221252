import React, { createContext, useReducer } from "react";
import * as yup from "yup";

export enum AppActions {
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  UPDATE_PACKAGES = "UPDATE_PACKAGES",
  UPDATE_CONTACT_DETAILS = "UPDATE_CONTACT_DETAILS",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  UPDATE_POSITION = "UPDATE_POSITION",
  UPDATE_BILLING = "UPDATE_BILLING",
  SET_CONFIG = "SET_CONFIG",
}

const URL_REGEXP =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const URL_REGEXP_NOT_STRICT =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export type PackagesState = number | null;

// Company
export const companyValidationSchema = yup.object({
  companyName: yup.string().required(),
  companyWebsite: yup.string().matches(URL_REGEXP).required(),
  industry: yup.string().notOneOf(["0"]).required(),
});
export type CompanyState = yup.InferType<typeof companyValidationSchema>;

// Contact
export const contactDetailsValidationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  jobTitle: yup.string().optional().default(""),
  email: yup.string().email().required(),
});
export type ContactDetailsState = yup.InferType<
  typeof contactDetailsValidationSchema
>;

// Position
export const positionValidationSchema = yup.object({
  jobTitle: yup.string().required(),
  locationTitle: yup.string().optional().default(""),
  locationPlaceId: yup.string().optional().default(""),
  jobCategoryName: yup.string().required().notOneOf(["0"]),
  lastApplicationDate: yup.string().required(),
  description: yup.string().required(),
  applyFormLink: yup.string().matches(URL_REGEXP_NOT_STRICT).required(),
});
export type PositionState = yup.InferType<typeof positionValidationSchema>;

// Billing
export const billingValidationSchema = yup.object({
  billingAddress: yup.string().required(),
  informationDetails: yup.string(),
  billingReference: yup.string().required(),
  consentTermsAndConditions: yup.string().required(),
});
export type BillingState = yup.InferType<typeof billingValidationSchema>;

export interface AppAction {
  type: AppActions;
  payload:
    | PackagesState
    | ContactDetailsState
    | CompanyState
    | BillingState
    | PositionState
    | ConfigState
    | string;
}

export interface ICompanyIndustries {
  code: number;
  name: string;
}

export interface ConfigState {
  companyIndustries: ICompanyIndustries[] | null;
  jobFunctions: string[] | null;
}

export interface InitialAppState {
  language: string;
  config: ConfigState;
  data: {
    packages: PackagesState;
    position: PositionState;
    contactDetails: ContactDetailsState;
    company: CompanyState;
    billing: BillingState;
  };
}

export const initialState: InitialAppState = {
  language: "EN",
  config: {
    companyIndustries: null,
    jobFunctions: null,
  },
  data: {
    packages: null,
    contactDetails: { firstName: "", lastName: "", email: "", jobTitle: "" },
    position: {
      jobTitle: "",
      locationTitle: "",
      locationPlaceId: "",
      jobCategoryName: "0",
      lastApplicationDate: "",
      description: "",
      applyFormLink: "",
    },
    company: { companyName: "", companyWebsite: "", industry: "0" },
    billing: {
      billingAddress: "",
      informationDetails: "",
      billingReference: "",
      consentTermsAndConditions: "true",
    },
  },
};

export const AppCtx = createContext<{
  state: InitialAppState;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    (state: InitialAppState, action: AppAction): InitialAppState => {
      switch (action.type) {
        case "UPDATE_LANGUAGE":
          return {
            ...state,
            language: action.payload as string,
          };
        case "UPDATE_PACKAGES":
          return {
            ...state,
            data: { ...state.data, packages: action.payload as PackagesState },
          };
        case "UPDATE_CONTACT_DETAILS":
          return {
            ...state,
            data: {
              ...state.data,
              contactDetails: action.payload as ContactDetailsState,
            },
          };
        case "UPDATE_COMPANY":
          return {
            ...state,
            data: { ...state.data, company: action.payload as CompanyState },
          };
        case "UPDATE_POSITION":
          return {
            ...state,
            data: { ...state.data, position: action.payload as PositionState },
          };
        case "UPDATE_BILLING":
          return {
            ...state,
            data: { ...state.data, billing: action.payload as BillingState },
          };
        case "SET_CONFIG":
          return {
            ...state,
            config: action.payload as ConfigState,
          };
        default:
          throw new Error();
      }
    },
    initialState
  );

  return (
    <AppCtx.Provider value={{ state, dispatch }}>{children}</AppCtx.Provider>
  );
};
