import React from 'react';
import TextAligmentToolbar from './TextAligmentToolbar';
import TextSizeToolbar from './TextSizeToolbar';
import { useTestId } from '../testId';

const CareerTextToolbar = () => {
  useTestId('careerTextToolbar');

  return (
    <>
      <TextAligmentToolbar />
      <TextSizeToolbar />
    </>
  );
};

export default CareerTextToolbar;
