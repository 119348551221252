import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import '../wysiwyg/placeholder.sass';
import getPlaceholderModule from 'quill-placeholder-module';
import { Quill } from 'react-quill';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import placeHolderConstants from '../PlaceHolderWysiwyg';
import { useTestId } from '../testId';

Quill.register(
  'modules/placeholder',
  getPlaceholderModule(Quill, {
    className: 'ql-placeholder',
  })
);

const id = uuidv4();
const EmailVariablesToolbar = () => {
  const { t } = useTranslation();
  const testId = useTestId('emailVariablesToolbar');

  const translationForCss = t('html-editor.variables');

  const translationStyleObject = useMemo(
    () => ({
      '--translatedVariablesString': `"${translationForCss}"`,
    }),
    [translationForCss]
  );

  useEffect(() => {
    const elem = document.querySelector('.ql-select-variable .ql-selected');

    if (elem) {
      elem.classList.remove('ql-selected');
    }
  }, []);

  return (
    <>
      {/* <TextAligmentToolbar /> */}
      <span
        className="ql-formats"
        style={translationStyleObject}
        data-testid={testId}
        data-tip={t('html-editor.insert-variables')}
        href="#"
        id={`insert-variables-${id}`}
      >
        <UncontrolledTooltip
          placement="top"
          data-testid={`${testId}--tooltip`}
          target={`insert-variables-${id}`}
        >
          {"Insert variable"}
        </UncontrolledTooltip>
        <select
          className="ql-placeholder ql-select-variable"
          data-testid={`${testId}--selectVariable`}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          defaultValue=""
        >
          {placeHolderConstants.map((item) => (
            <option
              data-testid={`${testId}--${item.id}`}
              key={item.id}
              value={item.id}
            >
              {t(item.translationKey)}
            </option>
          ))}
        </select>
      </span>
    </>
  );
};

export default React.memo(EmailVariablesToolbar);
