import React, { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { useFormik } from "formik";
import Stepper, { steps } from "../../components/Stepper/Stepper";
import CookieLink from "../../components/CookieLink/CookieLink";
import { formWidth } from "../../theme";
import {
  AppCtx,
  ContactDetailsState,
  contactDetailsValidationSchema,
} from "../../store/store";
import Page from "../../components/Page/Page";
import { useTranslation } from "react-i18next";

export type ContactDetailsProps = {};
const ContactDetails: FC<ContactDetailsProps> = () => {
  const { t } = useTranslation();
  const {
    state: {
      data: { contactDetails, packages },
    },
    dispatch,
  } = useContext(AppCtx);
  const navigate = useNavigate();
  const formik = useFormik<ContactDetailsState>({
    initialValues: contactDetails,
    validationSchema: contactDetailsValidationSchema,
    onSubmit: (values) => {
      dispatch({ type: "UPDATE_CONTACT_DETAILS", payload: values });
      navigate("/company");
    },
  });

  useEffect(() => {
    if (packages === null) {
      navigate("/");
    }
  }, []);

  const onNext = () => {
    formik.handleSubmit();
  };

  const onPrev = () => {
    navigate("/");
  };

  return (
    <Page
      title={t("pages.contact.page-title")}
      titleWidth={formWidth}
      FooterContent={() => (
        <Stepper
          onNext={onNext}
          onPrev={onPrev}
          activeStep={steps.findIndex((step) => step === "Contact")}
        />
      )}
      enforceDirection="column"
    >
      <Box maxWidth={formWidth} width={"100%"} boxSizing={"border-box"}>
        <form onSubmit={formik.handleSubmit}>
          <InputLabel>{t("general.email")}*</InputLabel>
          <TextField
            fullWidth
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <Box mt={2} />
          <InputLabel>{t("general.first-name")}*</InputLabel>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
          <Box mt={2} />
          <InputLabel>{t("general.last-name")}*</InputLabel>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
          <Box mt={2} />
          <InputLabel>{t("general.your-job-title")}</InputLabel>
          <TextField
            fullWidth
            id="jobTitle"
            name="jobTitle"
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
          />
        </form>
      </Box>
      <Box mt={2} mb={2} />
      <CookieLink />
      <Box mt={2} mb={2} />
    </Page>
  );
};

export default ContactDetails;
