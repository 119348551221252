/* eslint-disable */
import React, { useContext, createContext, useRef, useMemo } from 'react';

const TestIdContext = createContext(undefined);

// eslint-disable-next-line no-unused-vars
const cyrb53 = (str, seed = 0) => {
  // eslint-disable-next-line no-bitwise
  let h1 = 0xdeadbeef ^ seed;
  // eslint-disable-next-line no-bitwise
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i += 1) {
    ch = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    h1 = Math.imul(h1 ^ ch, 2654435761);
    // eslint-disable-next-line no-bitwise
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  // eslint-disable-next-line no-bitwise
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  // eslint-disable-next-line no-bitwise
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  // eslint-disable-next-line no-bitwise
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const TestIdProvider = ({ children, id }) => {
  const testIds = id ? [id] : [];
  const generatedIds = {};
  const generatedId = useRef('');

  const generateTestId = () => {
    const generatedTesTId = testIds.join('-');
    let indexedTestId = `${generatedTesTId}`;

    if (Object.keys(generatedIds).includes(generatedTesTId)) {
      generatedIds[generatedTesTId] += 1;
      indexedTestId = `${generatedTesTId}--${generatedIds[generatedTesTId]}`;
    } else {
      generatedIds[generatedTesTId] = 0;
    }

    generatedId.current = indexedTestId;
  };

  const addTestId = (testId) => {
    if (!testIds.includes(testId)) {
      testIds.push(testId);
    }
  };

  const data = {
    addTestId,
    generatedId,
    generateTestId,
  };

  return (
    <TestIdContext.Provider value={data}>{children}</TestIdContext.Provider>
  );
};

const useTestId = (id, values = []) => {
  const context = useContext(TestIdContext);

  if (context === undefined) {
    throw new Error('useTestId can only be used inside of the TestIdProvider');
  }
  const { addTestId, generateTestId, generatedId } = context;

  return useMemo(() => {
    addTestId(id);
    generateTestId();
    return cyrb53(generatedId.current);
  }, values);
};

export { TestIdProvider, useTestId, cyrb53 };
