import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Theme, useMediaQuery } from "@mui/material";

export type CenteredCellProps = {
  backgroundColor?: string;
};

const CenteredCell: FC<CenteredCellProps> = ({ children, backgroundColor }) => {
  const showMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box
      flex={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      sx={{ backgroundColor: backgroundColor || "transparent" }}
      height={showMobile ? "auto" : "100%"}
      width={showMobile ? "100%" : "auto"}
      boxSizing={"border-box"}
      p={4}
    >
      {children}
    </Box>
  );
};

export default CenteredCell;
