import React from 'react';
import { useTestId } from '../testId';

const Toolbar = ({ children, toolbarId }) => {
  const testId = useTestId('toolbar', [children]);
  return (
    <div data-testid={testId} id={toolbarId}>
      {children}
    </div>
  );
};

export default React.memo(Toolbar);
