import { FC } from "react";
import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page/Page";
import CookieLink from "../../components/CookieLink/CookieLink";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTranslation } from "react-i18next";

type ThankYouProps = {};

const ThankYou: FC<ThankYouProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const steps = [
    {
      label: t("pages.thank-you.step-1"),
    },
    {
      label: t("pages.thank-you.step-2"),
    },
    {
      label: t("pages.thank-you.step-3"),
    },
    {
      label: t("pages.thank-you.step-4"),
    },
    {
      label: t("pages.thank-you.step-5"),
    },
  ];

  return (
    <Page title={t("pages.thank-you.page-title")} enforceDirection="column">
      <Stepper activeStep={0} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={
                index > 0
                  ? () => <RadioButtonUncheckedIcon style={{ fontSize: 16 }} />
                  : undefined
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box display="flex" mt={4} mb={4} sx={{ gap: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/")}
        >
          {t("general.new-order")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => window.open("https://www.weselect.com/contact")}
        >
          {t("general.contact-us")}
        </Button>
      </Box>
      <CookieLink />
    </Page>
  );
};

export default ThankYou;
